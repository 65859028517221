import { Link } from "gatsby"
import React from "react"

const HeroPost = ({ post: data }) => {
  const [post, setPost] = React.useState(null)
  React.useEffect(() => {
    console.log("data", data)
    setPost(data)
  }, [data])
  return (
    <div className="post-snippet mb64">
      {post ? (
        <>
          <Link to={post.slug}>
            {post.iframe ? (
              <div
                className="embed-video-container embed-responsive embed-responsive-16by9"
                dangerouslySetInnerHTML={{ __html: post.iframe }}
              ></div>
            ) : (
              post.thumbnail && (
                <img
                  className="mb24"
                  alt={post.title}
                  src={post.thumbnail.file.url}
                />
              )
            )}
          </Link>
          <div className="post-title">
            <span className="label">{post.publishedDate}</span>
            <Link to={`/blog/${post.slug}`}>
              <h4 className="inline-block">{post.title}</h4>
            </Link>
          </div>
          <ul className="post-meta">
            <li>
              <i className="ti-tag"></i>
              <span>
                Tagged as
                <Link to={`/blog/${post.category}`}> {post.category}</Link>
              </span>
            </li>
          </ul>
          <hr />
          <p>{post.snippet}</p>
          <Link to={post.slug}>
            <p className="btn btn-sm">Read More</p>
          </Link>
        </>
      ) : (
        <div className="post-title">
          <h4 className="inline-block">No Posts</h4>
        </div>
      )}
    </div>
  )
}

export default HeroPost
